import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-70383213")
const _hoisted_1 = { class: "custom-dialog" }
const _hoisted_2 = { class: "custom-dialog" }
const _hoisted_3 = { class: "custom-dialog-content" }
const _hoisted_4 = { class: "content thin-scroll" }
const _hoisted_5 = { class: "btn-group" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ])
      ])
    ])
  ], 512)), [
    [_vShow, _ctx.modal]
  ])
}